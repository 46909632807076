import React from 'react';
import { Card, CardContent, Typography, Container } from '@mui/material';
import logo from '../resources/img/IA_Logo.png'

export const LandingPage = () => {
    return (
        <div>
            <Container>
                <Card style={{ textAlign: 'center' }}>
                    <CardContent>
                        <Typography variant="h4" style={{ marginTop: '1em', marginBottom: '1em' }} gutterBottom>
                            Welcome to Internal Admin
                        </Typography>
                        <div>
                            <img src={logo} height="80" alt="Logo" />
                        </div>
                        <Typography variant="body1" style={{ marginTop: '2em', marginBottom: '2em' }} paragraph>
                            You can access all of your available features and functionalities from the Menu on the left.
                        </Typography>
                    </CardContent>
                </Card>
            </Container>
        </div>
    );
};
